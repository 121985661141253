



































































































































































































































































































































































































































































import { IPagination } from '@/interfaces/pagination';
import { IService, IServicesResponse } from '@/interfaces/service';
import { IServiceCategory } from '@/interfaces/servicecategory';
import { ITag } from '@/interfaces/tag';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    data() {
        return {

            params: {
                active: null,
                source: null,
                name: '',
                sortBy: 'id',
                orderBy: 'asc',

                category: undefined as any | IServiceCategory,
                tags: [] as Array<ITag>,
                page: 1,

            },
            services: {
                data: [] as Array<IService>,
                meta: {} as IPagination | any,
            } as IServicesResponse,
        };
    },
    async mounted() {
        this.getServices();
    },
    methods: {
        getServices(page: number = 1): void {
            this.params.page = page;

            const url = 'services';
            // if (this.params.client) {
            //     url = `clients/${this.params.client.id}/services`;
            // }

            this.get<IServicesResponse>(url, {
                params: {
                    ...this.params,

                    category_id: this.params.category ? this.params.category.id : null,
                    tags: this.params.tags.map((t: ITag) => t.id),
                },
            }).then(({ data }) => {
                this.services = data;
            });
        },
    },
});

export default component;
